const Footer = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "auto",
          backgroundColor: "gray",
          padding: "3em 0",
          color: "white",
        }}
      >
        <div style={{ fontSize: "1.3em" }}>
          Instructor Notes for Helping Students Learn HTML/CSS/Javascript
        </div>
      </div>
    </>
  );
};

export default Footer;

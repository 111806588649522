import { ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { INSTRUCTOR_JSON } from "../assets";
import { UNITS } from "./constants/units.constants";

const TopicListInstructor = (props) => {
 
  const navigate = useNavigate();
  function handleClick(url) {
    navigate(url);
  }
  return (
    <>
      <h3>Topics</h3>

      {UNITS.map((unit, index) => (
        <>
          <h5>Unit {unit}:</h5>
          <ListGroup as="ul" style={{ marginBottom: "15px" }}>
            {INSTRUCTOR_JSON.filter((repl) => repl.unit === unit).map(
              (item) => (
                <ListGroup.Item
                  as="li"
                  action
                  href={() => handleClick(item.url)}
                >
                  {item.title}
                </ListGroup.Item>
              )
            )}
          </ListGroup>
        </>
      ))}
    </>
  );
};

export default TopicListInstructor;

import { NavLink } from "react-router-dom";

const MainPage = (props) => {
  return (
    <>
      <h2>Nothing to display</h2>
      <p>Notify Your Instructor If You See This Page</p>
    </>
  );
  // return (
  //   <>
  //     <h2>About Teacher Notes</h2>
  //     <p>
  //       Please select from the below the topic, lesson, or Repl with answer key.
  //       If you have any suggestions please let us know.
  //     </p>
  //     <ul>
  //       <li>
  //         <NavLink to={"/lessons/unit1/"}>Instructor Notes</NavLink>
  //       </li>
  //       <li>
  //         <NavLink to={"/repl/"}>Repl</NavLink>
  //       </li>
  //       <li>
  //         <NavLink to={"/student/unit1/"}>Student Challenge</NavLink>
  //       </li>
  //     </ul>
  //   </>
  // );
};

export default MainPage;

import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import rehypeRaw from "rehype-raw";
import { tomorrow as dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import ReactMarkdown from "react-markdown";
import { getPageId } from "./helper/getPageId";
import { getJSONFile } from "./helper/getJSONFile";
import CodeCopyBtn from "./CopyCodeButton";
const StudentSolutionPage = (props) => {
  const [post, setPost] = useState(`Loading...`);
  const location = useLocation();
  const JSON_FILE = getJSONFile(location.pathname);
  const { path } = useParams();
  const filePath = getPageId(JSON_FILE, path);

  const Pre = ({ children }) => (
    <pre className="blog-pre">
      <CodeCopyBtn>{children}</CodeCopyBtn>
      {children}
    </pre>
  );
  useEffect(() => {
    import(`../md/${filePath.solution}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setPost(res));
      })
      .catch((error) => console.warn(error));
  });

  return (
    <>
      <ReactMarkdown
        children={post}
        rehypePlugins={[rehypeRaw]}
        components={{
          pre: Pre,
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, "")}
                style={dark}
                language={match[1]}
                PreTag="div"
                showLineNumbers
                wrapLongLines={true}
                startingLineNumber={1}
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      />
    </>
  );
};

export default StudentSolutionPage;

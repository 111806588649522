import repl from "./repl.json";
import instructor from "./instructor.json";
import student from "./student.json";
import javascript from "./javascript.json";
import html_file from "./html.json";
import students_admin from "./students-admin.json";
import course_quiz from "./course-quiz.json";
import assignments from "./assignments.json";
import python from "./python.json";

export const REPL_JSON = repl;
export const INSTRUCTOR_JSON = instructor;
export const STUDENT_JSON = student;
export const JAVASCRIPT_COURSE = javascript;
export const HTML_COURSE = html_file;

export const STUDENTS_ADMIN_JSON = students_admin;

export const COURSE_QUIZ_JSON = course_quiz;
export const ASSIGNMENTS_JSON = assignments;
export const PYTHON_COURSE = python;

import { NavLink } from "react-router-dom";

const Header = (props) => {
  return (
    <>
      {" "}
      <header class="container-fluid text-center text-white p-5 mb-5 bg-primary">
        <NavLink to={"/"} style={{ color: "white" }}>
          <h1 class="jp-font jp-header">&#123;Code : Notes&#125;</h1>
        </NavLink>
      </header>
    </>
  );
};

export default Header;

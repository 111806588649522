import { Routes, Route } from "react-router-dom";
import {
  HTML_SECTIONS,
  JAVASCRIPT_SECTIONS,
  PYTHON_SECTIONS,
  SECTIONS,
} from "./constants/sections.constants";
import InstructorPage from "./InstructorNotes/InstructorPage";
import MainPage from "./MainPage";
import ReplPage from "./REPL/ReplPage";
import SlideExample from "./slides/SlideExample";
import StudentPage from "./StudentPage";
import ContentPage from "./InstructorNotes/ContentPage";
import QuizPage from "./QuizPage";
import StudentAdminPage from "./StudentAdminPage";

const RouterRoutes = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/repl/" element={<ReplPage />} />
        <Route path="/quiz/:category" element={<QuizPage />} />

        <Route path="/repl/:path" element={<ReplPage />} />
        {SECTIONS.map((unitName, index) => (
          <>
            <Route
              path={`/student/${unitName}/:path`}
              element={<StudentPage />}
            />
            <Route path={`/student/${unitName}/`} element={<StudentPage />} />
            <Route
              path={`/lessons/${unitName}/:path`}
              element={<InstructorPage />}
            />
            <Route
              path={`/lessons/${unitName}/`}
              element={<InstructorPage />}
            />
          </>
        ))}
        {JAVASCRIPT_SECTIONS.map((chapterName, index) => (
          <Route
            key={index}
            path={`/javascript/${chapterName}/:path`}
            element={<ContentPage />}
          />
        ))}
        {HTML_SECTIONS.map((chapterName, index) => (
          <Route
            key={index}
            path={`/html/${chapterName}/:path`}
            element={<ContentPage />}
          />
        ))}
        {PYTHON_SECTIONS.map((chapterName, index) => (
          <Route
            key={index}
            path={`/python/${chapterName}/:path`}
            element={<ContentPage />}
          />
        ))}

        <Route path="/student-admin" element={<StudentAdminPage />} />
        <Route path="/student-admin/:path" element={<StudentAdminPage />} />

        <Route path="*" element={<MainPage />} />
      </Routes>
    </>
  );
};

export default RouterRoutes;

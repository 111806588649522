import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";

import { useParams } from "react-router-dom";
import StudentList from "./StudentList";
import {
  ASSIGNMENTS_JSON,
  COURSE_QUIZ_JSON,
  STUDENTS_ADMIN_JSON,
} from "../assets";
const StudentAdminPage = (props) => {
  const { path } = useParams();

  if (!path)
    return (
      <Row>
        <Col md={3}>
          <StudentList />
        </Col>
        <Col></Col>
      </Row>
    );

  const name = STUDENTS_ADMIN_JSON.find((student) => student.id == path).name;
  const studentID = STUDENTS_ADMIN_JSON.find(
    (student) => student.id == path
  ).id;
  // create a function that will open a new tab with the url that is passed to it
  const handleClick = (lessonID) => {
    let pathURL = `https://www.precodecamp.com/dashboard/student_submissions/${studentID}?lesson_id=${lessonID}`;
    window.open(pathURL, "_blank");
  };
  return (
    <Row>
      <Col md={3}>
        <StudentList />
      </Col>
      <Col>
        <h3>{name}</h3>
        <h5>Quizzes</h5>
        <ListGroup as="ul" style={{ marginBottom: "15px" }}>
          {COURSE_QUIZ_JSON.map((quiz, index) => (
            <ListGroup.Item
              key={index}
              as="li"
              action
              href={() => handleClick(quiz.id)}
            >
              {quiz.name}{" "}
            </ListGroup.Item>
          ))}
        </ListGroup>
        <h5>Assignments</h5>
        <ListGroup as="ul" style={{ marginBottom: "15px" }}>
          {ASSIGNMENTS_JSON.map((assignment, index) => (
            <ListGroup.Item
              key={index}
              as="li"
              action
              href={() => handleClick(assignment.id)}
            >
              {assignment.name}{" "}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
    </Row>
  );
};

export default StudentAdminPage;

import { Col, Row } from "react-bootstrap";
import Page from "../Page";

const ContentPage = (props) => {
  return (
    <Row>
      <Col>
        <Page />
      </Col>
    </Row>
  );
};

export default ContentPage;

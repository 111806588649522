import {
  HTML_COURSE,
  INSTRUCTOR_JSON,
  JAVASCRIPT_COURSE,
  PYTHON_COURSE,
  REPL_JSON,
  STUDENT_JSON,
} from "../../assets";

export const getJSONFile = (location) => {
  const path = location.split("/")[1];
  switch (path) {
    case "repl":
      return REPL_JSON;
    case "lessons":
      return INSTRUCTOR_JSON;
    case "student":
      return STUDENT_JSON;
    case "javascript":
      return JAVASCRIPT_COURSE;
    case "html":
      return HTML_COURSE;
    case "python":
      return PYTHON_COURSE;
    default:
      return REPL_JSON;
  }
};

export const SECTIONS = [
  "unit1",
  "unit2",
  "unit3",
  "unit4",
  "unit5",
  "unit6",
  "unit7",
];

export const JAVASCRIPT_SECTIONS = [
  "chapter1",
  "chapter2",
  "challenge",
  "workbook",
];
export const HTML_SECTIONS = ["chapter1", "chapter2", "challenge"];
export const PYTHON_SECTIONS = ["chapter1", "chapter2", "challenge"];

import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import rehypeRaw from "rehype-raw";
import { tomorrow as dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import ReactMarkdown from "react-markdown";
import { getPageId } from "./helper/getPageId";
import { getJSONFile } from "./helper/getJSONFile";
import CodeCopyBtn from "./CopyCodeButton";
import { swarmify_script } from "./constants/swarmify.script";

const Pre = ({ children }) => (
  <pre className="blog-pre">
    <CodeCopyBtn>{children}</CodeCopyBtn>
    {children}
  </pre>
);

const Page = (props) => {
  const [post, setPost] = useState(``);
  const location = useLocation();
  const JSON_FILE = getJSONFile(location.pathname);
  const { path } = useParams();
  const filePath = getPageId(JSON_FILE, path);

  useEffect(() => {
    import(`../md/${filePath.markdown}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setPost(res));
      })
      .catch((error) => console.warn(error));
  });

  // useEffect(() => {
  //   if (post) {
  //     document
  //       .querySelector("#root")
  //       .insertAdjacentHTML("afterend", swarmify_script);
  //   }
  // }, [post]);

  //   useEffect(() => {
  //     if (post) {
  //       // run script to add swarmify to code blocks
  //       let bodyScript = document.querySelector("body");
  //       let script = `<script data-cfasync="false">
  //      var swarmoptions = {
  //          swarmcdnkey: "c1748ad3-2f4c-4122-98a9-550a5b343f02",
  //          iframeReplacement: "iframe",
  //          autoreplace: {
  //              youtube: true
  //          },
  //          theme: {
  //              primaryColor: "#0aaf3d"
  //          }
  //      };
  //  </script>
  //  <script async data-cfasync="false" src="https://assets.swarmcdn.com/cross/swarmdetect.js"></script>`;
  //       document.body.innerHTML = bodyScript.innerHTML + script;
  //     }
  //   }, [post]);
  if (!post) return <div>Loading...</div>;
  return (
    <>
      <ReactMarkdown
        children={post}
        rehypePlugins={[rehypeRaw]}
        components={{
          pre: Pre,
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <SyntaxHighlighter
                // lineProps={{ className: "code-line" }}
                children={String(children).replace(/\n$/, "")}
                style={dark}
                language={match[1]}
                PreTag="div"
                showLineNumbers
                // wrapLongLines={true}
                startingLineNumber={1}
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      />
    </>
  );
};

export default Page;

import React, { useState, useEffect } from "react";

import "./App.css";
import { Container } from "react-bootstrap";
import RouterRoutes from "./components/Routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";

function App() {
  const [isSinglePage, setIsSinglePage] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const singlePage = queryParams.get("singlepage");
    if (singlePage == true || singlePage == null) {
      setIsSinglePage(false);
    } else {
      setIsSinglePage(true);
    }
  }, [location]);
  return (
    <div className="flexcontainer">
      {!isSinglePage && <Header />}
      <Container>
        <RouterRoutes />
      </Container>
      {!isSinglePage && <Footer />}
    </div>
  );
}

export default App;

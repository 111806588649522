import React, { useState } from "react";
import "./QuizPage.css";
import QUIZ_QUESTIONS from "../assets/quiz-questions.json";
import { useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
export default function QuizPage() {
  const { category } = useParams();
  const questions = QUIZ_QUESTIONS.filter((quiz) => quiz.category === category);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswerOptionClick = (isCorrect) => {
    if (isCorrect) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };
  return (
    <Container className="mt-5">
      <Row>
        <div className="app">
          {showScore ? (
            <div className="score-section">
              <p>
                You scored {score} out of {questions.length}
              </p>
              <button
                onClick={() => {
                  setCurrentQuestion(0);
                  setShowScore(false);
                  setScore(0);
                }}
                style={{ justifyContent: "center" }}
              >
                Try Again
              </button>
            </div>
          ) : (
            <>
              <div className="question-section">
                <div className="question-count">
                  <span>Question {currentQuestion + 1}</span>/{questions.length}
                </div>
                <div className="question-text">
                  {questions[currentQuestion].questionText}
                </div>
              </div>
              <div className="answer-section">
                {questions[currentQuestion].answerOptions.map(
                  (answerOption) => (
                    <button
                      onClick={() =>
                        handleAnswerOptionClick(answerOption.isCorrect)
                      }
                    >
                      {answerOption.answerText}
                    </button>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </Row>
    </Container>
  );
}

import { Col, Row } from "react-bootstrap";
import Page from "../Page";
import TopicListInstructor from "../TopicListInstructor";

const InstructorPage = (props) => {
  return (
    <Row>
      <Col md={3}>
        <TopicListInstructor />
      </Col>
      <Col>
        <Page />
      </Col>
    </Row>
  );
};

export default InstructorPage;

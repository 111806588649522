import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Page from "./Page";
import TopicListStudent from "./TopicListStudent";
import StudentSolutionPage from "./StudentSolutionPage";
import { useParams } from "react-router-dom";
const StudentPage = (props) => {
  const [showSolution, setShowSolution] = useState(false);
  const { path } = useParams();
  useEffect(() => {
    setShowSolution(false);
  }, [path]);
  return (
    <Row>
      <Col md={3}>
        <TopicListStudent />
      </Col>
      <Col>
        <Page />
        <div className="d-grid gap-2">
          <Button
            className="btn-lg"
            style={{ margin: "2em 0" }}
            onClick={() => setShowSolution(!showSolution)}
          >
            {showSolution ? "Hide Solution" : "Show Solution"}
          </Button>
        </div>

        {showSolution ? <StudentSolutionPage /> : null}
      </Col>
    </Row>
  );
};

export default StudentPage;

import { Col, Row } from "react-bootstrap";
import Page from "../Page";
import TopicListRepl from "../TopicListRepl";

const ReplPage = (props) => {
  return (
    <>
      <Row>
        <Col md={3}>
          <TopicListRepl />
        </Col>
        <Col md={9}>
          <Page />
        </Col>
      </Row>
    </>
  );
};

export default ReplPage;

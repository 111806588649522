import { ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { STUDENTS_ADMIN_JSON } from "../assets";
const StudentList = (props) => {
  const navigate = useNavigate();
  function handleClick(url) {
    let pathURL = "/student-admin/" + url;
    navigate(pathURL);
  }
  return (
    <>
      <h3>Student Names</h3>
      <ListGroup as="ul" style={{ marginBottom: "15px" }}>
        {STUDENTS_ADMIN_JSON.map((student, index) => (
          <ListGroup.Item as="li" action href={() => handleClick(student.id)}>
            {student.name}{" "}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};

export default StudentList;
